import { currentYear } from 'utils/date';

export const ROOT_FONT_SIZE = 16;

export const DOMAIN_NAME = 'lukaszwojcik.dev';

const workStartYear = 2007;

export const INFO = {
  mail: 'hello@lukaszwojcik.dev',
  linkedin: 'https://www.linkedin.com/in/lukaszwojcikdev',
  workStartYear,
  experienceYears: currentYear - workStartYear,
  experience: [
    {
      from: 2017,
      to: currentYear,
      company: 'UNIT9',
      role: 'Technical Leader',
    },
    {
      from: 2016,
      to: 2017,
      company: 'UNIT9',
      role: 'Frontend Developer',
    },
    { from: 2013, to: 2015, company: 'UNIT9', role: 'Flash Developer' },
    { from: 2013, to: 2013, company: 'Media4u', role: 'Frontend Developer' },
    { from: 2011, to: 2012, company: 'Sfera', role: 'Flash Developer' },
    {
      from: 2009,
      to: 2010,
      company: 'Kamikaze',
      role: 'Flash Developer',
    },
    {
      from: 2008,
      to: 2009,
      company: 'Digital One',
      role: 'Flash Developer',
    },
    { from: 2007, to: 2008, company: 'IWACOM', role: 'Full-stack Developer' },
  ],
};

export const RUN_ANIMATIONS = true;
