import React from 'react';
import { INFO } from 'config';
import { Company } from './styles';

export default function Experience() {
  const { experience } = INFO;
  return experience.map(({ from, to, company, role }, i) => (
    <Company key={i}>
      {from}-{to} {company}: {role}
    </Company>
  ));
}
