import styled, { keyframes } from 'styled-components';

const gradient = keyframes`
	0% {
		transform: translate3d(0%, 0%, 0);
	}
	50% {
		transform: translate3d(-50%, 0%, 0);
	}
	100% {
		transform: translate3d(0%, 0%, 0);
	}
`;
export const Wrapper = styled.div`
  z-index: -1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  height: 150%;
  width: 600%;
  background: linear-gradient(
    -45deg,
    #87cefa,
    #ee7752,
    #e73c7e,
    #23a6d5,
    #23d5ab
  );
  background-size: 100% 100%;
  transform: translate3d(0%, 0%, 0);
  will-change: transform;
  animation: ${gradient} 60s ease infinite;
`;
// export const Wrapper = styled.div`
//   z-index: -1;
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-size: cover;
//   background-image: url(${props => props.image});

//   & img {
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//   }
// `;
