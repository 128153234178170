import React, { createRef, useState } from 'react';
import { Link } from '@reach/router';
import { InView } from 'react-intersection-observer';
import { useSpring } from 'react-spring';
import { PrizeProviders } from 'projects';
import FWA from 'components/fwa';
import { getProjectUrl } from 'utils/projectsHelpers';
import { Wrapper, Preview, Name, NameImage, Gradient, Content } from './styles';
import initAnims from './animations';

export default function ProjectPreview(props) {
  const wrapper = createRef();

  const [visible, setVisible] = useState(false);

  const anims = initAnims(useSpring);

  const { name, year, preview, previewName, prizes, gradient } = props;

  const projectUrl = `/projects/${getProjectUrl(props)}`;

  return (
    <InView
      triggerOnce
      treshold={1}
      onChange={(inView, entry) => {
        if (inView) {
          setVisible(true);
          anims.setVisible(inView);
        }
      }}
    >
      <Wrapper ref={wrapper} {...anims.wrapper(wrapper)}>
        <Name>
          {year} - {name}
        </Name>
        <Content isVisible={visible}>
          <Link to={projectUrl}>
            <Preview {...preview} alt={name} {...anims.preview} />
            <Gradient {...gradient} {...anims.gradient} />
            <NameImage {...previewName} alt={name} {...anims.name} />
          </Link>
          {prizes &&
            prizes.map(
              ({ provider, sticker, url }, i) =>
                provider === PrizeProviders.fwa && (
                  <FWA type={sticker} key={i} url={url} name={name} />
                )
            )}
        </Content>
      </Wrapper>
    </InView>
  );
}
