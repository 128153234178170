import { css } from 'styled-components';

export const italic = css`
  font-style: italic;
`;

export const fontLight = css`
  font-weight: 300;
`;
export const fontLightItalic = css`
  ${fontLight}
  ${italic}
`;

export const fontMedium = css`
  font-weight: 400;
`;
export const fontMediumItalic = css`
  ${fontMedium}
  ${italic}
`;

export const fontBold = css`
  font-weight: 500;
`;
export const fontBoldItalic = css`
  ${fontBold}
  ${italic}
`;
