import React from 'react';
import { INFO } from 'config';
import { Medium } from 'components/fonts';
import Tooltip from 'components/tooltip';
import { Wrapper } from './styles';
import Experience from './experience';
import Prizes from './prizes';

export default function Info() {
  const { experience } = INFO;
  const current = experience[0];
  return (
    <Wrapper>
      Hello! My name is <Medium>Łukasz Wójcik</Medium>, and I’m
      <br />
      <Medium>{current.role}</Medium> at <Medium>{current.company}</Medium>.
      <br />
      <br />I have{' '}
      <Tooltip
        name="experience"
        link={<Medium>over {INFO.experienceYears} years of experience</Medium>}
        tooltip={<Experience />}
      />
      <br />
      in app / web development.
      {/* <br />
      Starting from <Medium>HTML + PHP</Medium>,<br />
      through <Medium>Flash/ActionScript</Medium>,<br />
      now <Medium>HTML + CSS + JavaScript</Medium>
      <br />
      and <Medium>AR</Medium> experiences. */}
      <br />
      <br />
      Projects that I helped creating <Medium>won prizes</Medium>:<br />
      <Prizes />
      <br />
      <br />
      {/* Want to know more about me? Check here! */}
    </Wrapper>
  );
}
