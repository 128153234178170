import styled from 'styled-components';
import { isDesktop } from 'utils/device';

export const Wrapper = styled.div`
  margin: 0 5% 5%;
  display: flex;
  align-items: ${isDesktop ? 'flex-start' : 'center'};
  flex-direction: ${isDesktop ? 'row' : 'column'};
  justify-content: space-around;
  min-height: 30vh;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${isDesktop ? '30%' : '100%'};
  margin: 4vmin;
`;
