import { isDesktop } from 'utils/device';

const theme = {
  color: {
    // Specific elements
    symbolBlue: '#6d9eeb',
    symbolYellow: '#f1c232',
    gradientColor1: '#c766fc',
    gradientColor2: '#6283fb',
    gradientColor3: '#67e3fd',

    // Primary palette
    black: '#000',
    blue: '#502efd',
    blue2: '#6d55ff',
    blue3: '#5029E3',
    blue4: 'rgb(32, 23, 157)',
    green: '#4cff8a',
    pink: '#eb41e6',
    pink2: '#fc00ff',
    pink3: '#ec3583',
    purple: '#b47efa',
    white: '#fff',
    header: 'rgba(135, 206, 250, 0.8)',
    headerBlendMode: 'blend-mode: color;',
  },
  font: {
    face: {
      primary: `'Roboto', sans-serif`,
      secondary: '',
    },
    weight: {
      primary: {
        light: 300,
        regular: 400,
        medium: 500,
      },
    },
    size: {
      primary: {
        mobile: {
          all: {},
        },
      },
      secondary: {
        mobile: {
          all: {},
        },
      },
    },
    lineHeight: {
      primary: {},
      secondary: {},
    },
  },
  layout: {
    headerSize: isDesktop ? '75px' : '50px',
    projectsNavSize: isDesktop ? '50px' : '30px',
  },
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 61, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
  zIndex: {
    header: 1,
    popup: 10,
    overlay: 9999,
  },
};

// Aliases
export const { color, font, layout, zIndex } = theme;

export default theme;
