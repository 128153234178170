/*
to get image color pallete

http://www.cssdrive.com/imagepalette/index.php

*/
import { FWA_TYPES } from 'components/fwa';

const double = (nr) => (nr < 10 ? `0${nr}` : nr.toString());
const repeat = (n, fn, ext) =>
  [...Array(n)].map((_, i) => fn(double(i + 1))(ext));

const getImage = (name) => (appendix) => (ext) =>
  require(`assets/images/projects/${name}_${appendix}.${ext}`);

const getImages = (count, name) =>
  count === 0
    ? [getImage(name)('preview')('jpg')]
    : repeat(count, getImage(name), 'jpg');

export const PrizeProviders = {
  fwa: 'FWA',
};
export const PrizeType = {
  fwa: {
    FOTD: 'FWA of the day',
    FOTM: 'FWA of the month',
    PCA: `People's Choice Award`,
    FOTY: 'FWA of the year',
  },
};

export default [
  {
    name: 'Bacardi: Carta Blanca AR',
    year: 2020,
    client: 'Bacardi',
    role: 'Tech Lead / Frontend Developer',
    // description: 'Snapchat AR Lens created with Lens Studio.',
    tech: 'Lens Studio',
    keywords: ['snapchat', 'AR', 'mobile', '3D'],
    preview: getImage('2020_bacardi_cartaBlancaAR')('preview')('jpg'),
    previewName: getImage('2020_bacardi_cartaBlancaAR')('name')('png'),
    gradient: { from: '#f2b527', to: '#66783c' },
    images: getImages(0, '2020_bacardi_cartaBlancaAR'),
  },
  {
    name: 'Nike: Move To Zero',
    url: 'https://www.unit9.com/project/nike-move-zero',
    year: 2020,
    client: 'Nike',
    role: 'Tech Lead / Frontend Developer',
    // description: 'Interactive advertising website with AR - 3D model presented over the shoebox',
    tech: 'HTML',
    keywords: ['html', 'javascript', 'react', 'AR', 'website', 'mobile', '3D'],
    preview: getImage('2020_nike_moveToZero')('preview')('jpg'),
    previewName: getImage('2020_nike_moveToZero')('name')('png'),
    prizes: [
      {
        provider: PrizeProviders.fwa,
        type: PrizeType.fwa.FOTD,
        sticker: FWA_TYPES.FOTD.TOP_RIGHT,
        date: '03-04-2020',
        url: 'https://thefwa.com/cases/nike-move-to-zero',
      },
    ],
    gradient: { from: '#c0b194', to: '#a4be27' },
    vimeo: 391980889,
    images: getImages(0, '2020_nike_moveToZero'),
  },
  {
    name: `Diageo: What's Your Whisky`,
    url: 'https://www.unit9.com/project/diageo-whats-whisky-selector',
    year: 2019,
    client: 'Diageo',
    role: 'Tech Lead / Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: [
      'html',
      'javascript',
      'react',
      'website',
      'mobile',
      'flavor print',
    ],
    preview: getImage('2019_diageo_whatsYourWhisky')('preview')('jpg'),
    previewName: getImage('2019_diageo_whatsYourWhisky')('name')('png'),
    gradient: { from: '#fccb7c', to: '#d96c2b' },
    vimeo: 396651170,
    images: getImages(0, '2019_diageo_whatsYourWhisky'),
  },
  {
    name: 'Google: Search Sounds',
    url: 'https://www.unit9.com/project/google-search-sounds',
    year: 2019,
    client: 'Google',
    role: 'Tech Lead / Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: ['html', 'javascript', 'angular', 'website', 'mobile'],
    preview: getImage('2019_google_searchSounds')('preview')('jpg'),
    previewName: getImage('2019_google_searchSounds')('name')('png'),
    prizes: [
      {
        provider: PrizeProviders.fwa,
        type: PrizeType.fwa.FOTD,
        sticker: FWA_TYPES.FOTD.TOP_RIGHT,
        date: '06-09-2019',
        url: 'https://thefwa.com/cases/google-search-sounds',
      },
    ],
    gradient: { from: '#3873b7', to: '#00982b' },
    vimeo: 351398591,
    images: getImages(0, '2019_google_searchSounds'),
  },
  {
    name: 'Nike: Wall Of Dreams',
    url: 'https://www.unit9.com/project/nike-wall-dreams',
    year: 2019,
    client: 'Nike',
    role: 'Tech Lead / Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: ['html', 'javascript', 'react', 'website', 'desktop', 'mobile'],
    preview: getImage('2019_nike_wallOfDreams')('preview')('jpg'),
    prizes: [
      {
        provider: PrizeProviders.fwa,
        type: PrizeType.fwa.FOTD,
        sticker: FWA_TYPES.FOTD.TOP_RIGHT,
        date: '24-08-2019',
        role: 'Frontend Developer',
        url: 'https://thefwa.com/cases/nike-wall-of-dreams-p3',
      },
    ],
    gradient: { from: '#ffe871', to: '#82251e' },
    vimeo: 348549259,
    images: getImages(0, '2019_nike_wallOfDreams'),
  },
  {
    name: 'Coors Light: Smart Tap',
    url: 'https://www.unit9.com/project/coors-light-smart-tap',
    year: 2019,
    client: 'Coors Light',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: ['html', 'javascript', 'react', 'website', 'mobile'],
    preview: getImage('2019_coorslight_smartTap')('preview')('jpg'),
    previewName: getImage('2019_coorslight_smartTap')('name')('png'),
    prizes: [
      {
        provider: PrizeProviders.fwa,
        type: PrizeType.fwa.FOTD,
        sticker: FWA_TYPES.FOTD.TOP_RIGHT,
        date: '11-05-2019',
        role: 'Frontend Developer',
        url: 'https://thefwa.com/cases/coors-light-smart-tap',
      },
    ],
    gradient: { from: '#971634', to: '#bfa898' },
    vimeo: 333720506,
    images: getImages(0, '2019_coorslight_smartTap'),
  },
  {
    name: 'StubHub: The Gift Rapper',
    url: 'https://www.unit9.com/project/stubhub-gift-rapper',
    year: 2018,
    client: 'StubHub',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: ['html', 'javascript', 'react', 'website', 'desktop', 'mobile'],
    preview: getImage('2018_stubhub_giftrapper')('preview')('jpg'),
    previewName: getImage('2018_stubhub_giftrapper')('name')('png'),
    prizes: [
      {
        provider: PrizeProviders.fwa,
        type: PrizeType.fwa.FOTD,
        sticker: FWA_TYPES.FOTD.TOP_RIGHT,
        date: '10-12-2018',
        role: 'Frontend Developer',
        url: 'https://thefwa.com/cases/stubhub-gift-rapper',
      },
    ],
    gradient: { from: '#522278', to: '#ff78ac' },
    vimeo: 309531868,
    images: getImages(0, '2018_stubhub_giftrapper'),
  },
  {
    name: 'OnePlus: Crackables',
    url: 'https://www.unit9.com/project/oneplus-crackables',
    year: 2018,
    client: 'OnePlus',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: [
      'html',
      'javascript',
      'react',
      'website',
      'mobile',
      'game',
      'puzzle',
      'multiplayer',
      '3D',
    ],
    preview: getImage('2018_oneplus_crackables')('preview')('jpg'),
    previewName: getImage('2018_oneplus_crackables')('name')('png'),
    prizes: [
      {
        provider: PrizeProviders.fwa,
        type: PrizeType.fwa.FOTD,
        sticker: FWA_TYPES.FOTD.TOP_RIGHT,
        date: '18-11-2018',
        role: 'Frontend Developer',
        url: 'https://thefwa.com/cases/oneplus-crackables',
      },
    ],
    gradient: { from: '#d33158', to: '#8b99b6' },
    vimeo: 299905516,
    images: getImages(0, '2018_oneplus_crackables'),
  },
  {
    name: `TiViTz`,
    year: 2017,
    client: 'TiViTz',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: [
      'html',
      'javascript',
      'react',
      'desktop',
      'mobile',
      'game',
      'multiplayer',
      '3D',
    ],
    preview: getImage('2017_tivitz')('preview')('jpg'),
    previewName: getImage('2017_tivitz')('name')('png'),
    gradient: { from: '#dedbca', to: '#2c3eba' },
    images: getImages(0, '2017_tivitz'),
  },
  {
    name: `Galeria Victoria`,
    year: 2014,
    client: 'Digital Action',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'app', 'mobile'],
    preview: getImage('2014_galeria_victoria')('preview')('jpg'),
    gradient: { from: '#ff7a95', to: '#ffb696' },
    images: getImages(11, '2014_galeria_victoria'),
  },
  {
    name: `Ogólnopolska Olimpiada Młodzieży`,
    year: 2013,
    client: 'Digital Action',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: ['html', 'javascript', 'website', 'desktop', 'mobile'],
    preview: getImage('2013_olimpiada_mlodziezy')('preview')('jpg'),
    gradient: { from: '#f9aa27', to: '#1b90dd' },
    images: getImages(7, '2013_olimpiada_mlodziezy'),
  },
  {
    name: `My Mint Lips`,
    year: 2013,
    client: 'Digital Action',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'HTML',
    keywords: ['html', 'javascript', 'website', 'desktop', 'mobile'],
    preview: getImage('2013_mml')('preview')('jpg'),
    gradient: { from: '#f4dc6e', to: '#3badeb' },
    images: getImages(11, '2013_mml'),
  },
  {
    name: `Disney: PS3 Epic Mickey`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_ps3_epic_mickey')('preview')('jpg'),
    gradient: { from: '#76bdcf', to: '#036ea6' },
    images: getImages(7, '2012_disney_ps3_epic_mickey'),
  },
  {
    name: `Disney: Chi Chi Love`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_chi_chi_love')('preview')('jpg'),
    gradient: { from: '#f90089', to: '#93b23a' },
    images: getImages(10, '2012_disney_chi_chi_love'),
  },
  {
    name: `Disney: LEGO City`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_lego_city')('preview')('jpg'),
    gradient: { from: '#a8d7f5', to: '#f5cd16' },
    images: getImages(11, '2012_disney_lego_city'),
  },
  {
    name: `Disney: XBOX Dance Central`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_xbox_dance_central')('preview')('jpg'),
    gradient: { from: '#ea007a', to: '#2977c1' },
    images: getImages(7, '2012_disney_xbox_dance_central'),
  },
  {
    name: `FOX Nat Geo: Wild`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_fox_natgeo')('preview')('jpg'),
    gradient: { from: '#515151', to: '#22843b' },
    images: getImages(10, '2012_fox_natgeo'),
  },
  {
    name: `FOX Life: Miłosne rozgrywki`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_fox_life_milosne_rozgrywki')('preview')('jpg'),
    gradient: { from: '#faa0c4', to: '#ff378a' },
    images: getImages(16, '2012_fox_life_milosne_rozgrywki'),
  },
  {
    name: `Disney: XBOX Kinect Disneyland Adventures`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_xbox_kinect_disneyland_adventures')(
      'preview'
    )('jpg'),
    gradient: { from: '#eeb8c8', to: '#865aa5' },
    images: getImages(9, '2012_disney_xbox_kinect_disneyland_adventures'),
  },
  {
    name: `Disney: XBOX Star Wars`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_xbox_star_wars')('preview')('jpg'),
    gradient: { from: '#b29f8f', to: '#512620' },
    images: getImages(9, '2012_disney_xbox_star_wars'),
  },
  {
    name: `Disney: AgentP Crew Game`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_agentp_crew_game')('preview')('jpg'),
    gradient: { from: '#a5dbff', to: '#0d9aab' },
    images: getImages(4, '2012_disney_agentp_crew_game'),
  },
  {
    name: `Disney: Junior Dzień dziecka`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_junior_dzien_dziecka')('preview')('jpg'),
    gradient: { from: '#fcc705', to: '#88d845' },
    images: getImages(6, '2012_disney_junior_dzien_dziecka'),
  },
  {
    name: `Disney: Sony Walkman`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_sony_walkman')('preview')('jpg'),
    gradient: { from: '#ffaa4c', to: '#db2e59' },
    images: getImages(4, '2012_disney_sony_walkman'),
  },
  {
    name: `Disney: Video Player`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_video_player')('preview')('jpg'),
    gradient: { from: '#1290c2', to: '#004564' },
    images: getImages(1, '2012_disney_video_player'),
  },
  {
    name: `Disney: XBOX Kinect Rush`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_xbox_kinect_rush')('preview')('jpg'),
    gradient: { from: '#6d4ba1', to: '#513742' },
    images: getImages(9, '2012_disney_xbox_kinect_rush'),
  },
  {
    name: `Disney: Avengers`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_avengers')('preview')('jpg'),
    gradient: { from: '#4abdf3', to: '#b00a22' },
    images: getImages(9, '2012_disney_avengers'),
  },
  {
    name: `LEGO Friends`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_lego_friends')('preview')('jpg'),
    gradient: { from: '#cba3c1', to: '#9a3475' },
    images: getImages(10, '2012_lego_friends'),
  },
  {
    name: `FOX: Touch`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_fox_touch')('preview')('jpg'),
    gradient: { from: '#f09000', to: '#074129' },
    images: getImages(9, '2012_fox_touch'),
  },
  {
    name: `Disney: Auta 2`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_auta2')('preview')('jpg'),
    gradient: { from: '#8fafd5', to: '#2269ab' },
    images: getImages(8, '2012_disney_auta2'),
  },
  {
    name: `Disney: Skechers`,
    year: 2012,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_disney_skechers')('preview')('jpg'),
    gradient: { from: '#6053a3', to: '#0e4365' },
    images: getImages(7, '2012_disney_skechers'),
  },
  {
    name: `Business in Małopolska - CD presentation`,
    year: 2012,
    client: 'VMGnet',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_BIM_CD')('preview')('jpg'),
    gradient: { from: '#8b9597', to: '#1f2387' },
    images: getImages(8, '2012_BIM_CD'),
  },
  {
    name: `Bank Żywności - game`,
    year: 2012,
    client: 'B-Mind',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_bankZywnosci')('preview')('jpg'),
    gradient: { from: '#f5942d', to: '#37a42d' },
    images: getImages(2, '2012_bankZywnosci'),
  },
  {
    name: `GE: Healthcare`,
    year: 2012,
    client: 'Disco Volante',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_healthcare')('preview')('jpg'),
    gradient: { from: '#e0902f', to: '#a35719' },
    images: getImages(13, '2012_healthcare'),
  },
  {
    name: `Galbani: Christmas Card`,
    year: 2012,
    client: 'Atom',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_christmassCardGalbani')('01')('jpg'),
    gradient: { from: '#27a9df', to: '#0048a7' },
    images: getImages(1, '2012_christmassCardGalbani'),
  },
  {
    name: `Udachowieni: Dachowiec - game`,
    year: 2012,
    client: 'Atom',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2012_dachowiec')('preview')('jpg'),
    gradient: { from: '#df683e', to: '#a70000' },
    images: getImages(3, '2012_dachowiec'),
  },
  {
    name: `FOX Life: Gotowe na wszystko`,
    year: 2011,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_fox_life_gotowe_na_wszystko')('preview')('jpg'),
    gradient: { from: '#afafaf', to: '#eb3975' },
    images: getImages(9, '2011_fox_life_gotowe_na_wszystko'),
  },
  {
    name: `Disney: Bop It`,
    year: 2011,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_disney_bopit')('preview')('jpg'),
    gradient: { from: '#9f9c9a', to: '#862c86' },
    images: getImages(5, '2011_disney_bopit'),
  },
  {
    name: `FOX: Terra Nova`,
    year: 2011,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_fox_terranova')('preview')('jpg'),
    gradient: { from: '#8c9f9f', to: '#613f23' },
    images: getImages(5, '2011_fox_terranova'),
  },
  {
    name: `Disney: Clarks`,
    year: 2011,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_disney_clarks')('preview')('jpg'),
    gradient: { from: '#b69ce5', to: '#86226b' },
    images: getImages(8, '2011_disney_clarks'),
  },
  {
    name: `Disney: Zibits`,
    year: 2011,
    client: 'Sfera',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_disney_zibits')('preview')('jpg'),
    gradient: { from: '#9c50aa', to: '#301933' },
    images: getImages(11, '2011_disney_zibits'),
  },
  {
    name: `Samsung: Notebook Series 9`,
    year: 2011,
    client: 'Pro-Creation',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_samsungSite')('preview')('jpg'),
    gradient: { from: '#818181', to: '#080a09' },
    images: getImages(7, '2011_samsungSite'),
  },
  {
    name: `Dromader`,
    year: 2011,
    client: 'Atom',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_dromader')('preview')('jpg'),
    gradient: { from: '#c5e53a', to: '#0d3b55' },
    images: getImages(7, '2011_dromader'),
  },
  {
    name: `Atom: Christmas Card`,
    year: 2011,
    client: 'Atom',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_christmassCardAtom')('preview')('jpg'),
    gradient: { from: '#fc3230', to: '#2c1406' },
    images: getImages(2, '2011_christmassCardAtom'),
  },
  {
    name: `Strajk Łódzkich Studentów 1981`,
    year: 2011,
    client: 'Sparta',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2011_strajkiLodz')('preview')('jpg'),
    gradient: { from: '#515151', to: '#0b0b0b' },
    images: getImages(3, '2011_strajkiLodz'),
  },
  {
    name: `Mentos: Fresh Camp`,
    year: 2010,
    client: 'Kamikaze',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2010_mentos')('preview')('jpg'),
    gradient: { from: '#66b8f2', to: '#344c92' },
    images: getImages(17, '2010_mentos'),
  },
  {
    name: `Mleko`,
    year: 2010,
    client: 'Kamikaze',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2010_mleko')('preview')('jpg'),
    gradient: { from: '#75a418', to: '#293b47' },
    images: getImages(8, '2010_mleko'),
  },
  {
    name: `Pysio`,
    year: 2010,
    client: 'Kamikaze',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2010_pysio')('preview')('jpg'),
    gradient: { from: '#f1bc54', to: '#eb6f33' },
    images: getImages(19, '2010_pysio'),
  },
  {
    name: `Ad Days - CD presentation`,
    year: 2010,
    client: 'Kamikaze',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2010_addays')('preview')('jpg'),
    gradient: { from: '#5079a7', to: '#222222' },
    images: getImages(8, '2010_addays'),
  },
  {
    name: `PAWO`,
    year: 2010,
    client: 'Kamikaze',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2010_pawo')('preview')('jpg'),
    gradient: { from: '#dba17a', to: '#703423' },
    images: getImages(4, '2010_pawo'),
  },
  {
    name: `Wejchert Golf Club`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_wejchertGolfClub')('preview')('jpg'),
    gradient: { from: '#eba0c1', to: '#fe4701' },
    images: getImages(8, '2009_wejchertGolfClub'),
  },
  {
    name: `LU Go: games & desktop widgets`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_LUGo_Gry')('preview')('jpg'),
    gradient: { from: '#ffc10e', to: '#c2e80f' },
    images: getImages(8, '2009_LUGo_Gry'),
  },
  {
    name: `Pepsi: Edi mini site`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_PepsiEdi')('preview')('jpg'),
    gradient: { from: '#4ec8ed', to: '#003972' },
    images: getImages(7, '2009_PepsiEdi'),
  },
  {
    name: `Pepsi: Wygraj`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_pepsiWygraj')('preview')('jpg'),
    gradient: { from: '#d3101e', to: '#052383' },
    images: getImages(3, '2009_pepsiWygraj'),
  },
  {
    name: `Friskies: Desktop widgets`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_friskies_widgets')('preview')('jpg'),
    gradient: { from: '#faf4e4', to: '#754328' },
    images: getImages(3, '2009_friskies_widgets'),
  },
  {
    name: `Fortuna Karotka: Desktop widgets`,
    year: 2010,
    client: 'Kamikaze',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2010_fortuna_karotka_widgets')('preview')('jpg'),
    gradient: { from: '#61aff1', to: '#143791' },
    images: getImages(3, '2010_fortuna_karotka_widgets'),
  },
  {
    name: `LU Go: Desktop widgets`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_LUGo_desktop_widgets')('preview')('jpg'),
    gradient: { from: '#fec202', to: '#5b9914' },
    images: getImages(3, '2009_LUGo_desktop_widgets'),
  },
  {
    name: `Orange: BlackBerry presentation`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_OrangeBlackBerry')('preview')('jpg'),
    gradient: { from: '#6aa7d3', to: '#652a00' },
    images: getImages(6, '2009_OrangeBlackBerry'),
  },
  {
    name: `Orange: history presentation`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_OrangePrezentacja')('preview')('jpg'),
    gradient: { from: '#fad126', to: '#ff544f' },
    images: getImages(4, '2009_OrangePrezentacja'),
  },
  {
    name: `Oriflame: SILKY kiss`,
    year: 2009,
    client: 'Digital One',
    role: 'Frontend Developer',
    // description: 'Interactive advertising website with competition / mini game and prizes.',
    tech: 'Flash AS3',
    keywords: ['flash', 'AS3', 'website', 'desktop'],
    preview: getImage('2009_oriflame_SilkyKiss')('preview')('jpg'),
    gradient: { from: '#ef0a6a', to: '#b6359c' },
    images: getImages(5, '2009_oriflame_SilkyKiss'),
  },
];
