import { createGlobalStyle } from 'styled-components';
import theme, { color, layout } from './theme';
import { fontLight } from './mixins';

export default createGlobalStyle`
  html,
  body {
    width: 100%;
    color: ${color.white};
    overscroll-behavior: none;
    -webkit-text-size-adjust: 100%;
    overflow-x: hidden;
  }
  html{
    background-color: ${theme.color.black};
    // scroll-behavior: smooth;
  }

  body {
    font-family: ${theme.font.face.primary};
    letter-spacing: 0.075em;
    ${fontLight}
    position: relative;
  }
  *{
    margin: 0;
    padding: 0;
  }

  button, input, select, textarea {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    background: none;
    font-family: inherit;
    border: none;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    text-transform: inherit;
    outline: none;
  }

img {  
  position: relative;
  text-indent: -10000px;
  background: rgba(0,0,0,0);
}
  img[alt]:after {  
    text-indent: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    content: attr(alt);
  }

  svg {
    outline: none;
  }

  #root {
    min-width: 100%;
  }

  #root > div:last-of-type {
    margin-top: calc(${layout.headerSize} + 5%);
  }
  
  a {
    text-decoration: none;
    color: inherit;
    position: relative;
    display: inline-block;
  }
`;
