import styled from 'styled-components';
import { pxToVw, fontBold, layout, color } from 'styles';
import { isDesktop } from 'utils/device';

export const Wrapper = styled.div`
  ${color.headerBlendMode}
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${layout.headerSize};
  border-bottom: solid white 1px;
  background-color: ${color.header};

  font-size: 1.2em;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled.div`
  display: inline-block;
  line-height: ${layout.headerSize};
  text-align: center;
  font-size: ${pxToVw(isDesktop ? 20 : 72)};
  margin: ${pxToVw(50)} 0 ${pxToVw(50)} 4vmin;
`;

export const Contact = styled.div`
  margin-right: 4vmin;
  & a {
    opacity: 0.6;
    ${fontBold}
    display: inline-block;
    margin-left: 1em;
    height: 1em;
    line-height: 1em;
    width: 1em;
    text-align: center;
    vertical-align: middle;
    &: hover {
      opacity: 1;
    }
  }
`;
