import styled from 'styled-components';
import { animated } from 'react-spring';
import Image from 'components/image';
import { isDesktop } from 'utils/device';
import { fontMediumItalic, fontBold } from 'styles';

export const Wrapper = styled(animated.div)`
  position: relative;
  min-width: 100px;
  min-height: 100px;
  width: 70vmin;
  height: 70vmin;
  margin: 10vmin;
  border: solid white 2px;
  background: black;
  ${isDesktop &&
  `
  max-width: 300px;
  max-height: 300px;
  cursor: pointer;
  margin: 5vmin;
  
  `}
`;

const namePosition = `
    position: absolute;
    bottom: 10%;
    left: 10%;
`;
export const Content = styled.figure`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 200ms;

  & figcaption {
    ${fontBold}
    font-size: 1.4em;
  }
`;

export const Preview = styled(Image)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Gradient = styled(animated.div)`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background: linear-gradient(
    to bottom right,
    ${({ from }) => from},
    ${({ to }) => to}
  );
`;

export const NameImage = styled(Image)`
  z-index: 1;
  ${namePosition}
  max-width: 80%;
  max-height: 25%;
  opacity: 0;
  object-fit: contain;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
`;

export const Name = styled.div`
  ${fontMediumItalic}
  position: absolute;
  top: -1.6em;
  left: 0.5em;
  font-size: 0.8em;
  filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
`;
