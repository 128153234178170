import React from 'react';
import { Router, Redirect } from '@reach/router';
import Background from 'components/background';
import Bio from 'components/bio';
import Project from 'components/project';
import Projects from 'components/projects';
import Header from 'components/header';
import { isDesktop } from 'utils/device';

const HomePage = (props) => (
  <>
    <Bio />
    <Projects limit={isDesktop ? 8 : 4} />
  </>
);

const ProjectsPage = ({ children }) => children;

const NotFound = () => <Redirect to="/" noThrow />;

function App() {
  return (
    <>
      <Background />
      <Header />
      <Router>
        <NotFound default />
        <HomePage path="/" />
        <ProjectsPage path="projects">
          <Projects path="/" />
          <Project path=":url" />
        </ProjectsPage>
      </Router>
    </>
  );
}

export default App;
