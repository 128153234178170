import styled from 'styled-components';
import { isDesktop } from 'utils/device';
import Tooltip from 'react-tooltip';
import { LinkNote } from 'styles';

export const ReactTooltip = styled(Tooltip)`
  pointer-events: ${({ pointerEvents }) =>
    pointerEvents === true ? `auto !important` : 'none'};
`;

export const Link = styled.a`
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);

  ${LinkNote(`<-- ${isDesktop ? 'hover' : 'tap'} to see more`)}
`;
