import styled from 'styled-components';
import {
  fontLight,
  fontBold,
  fontMedium,
  fontLightItalic,
  fontMediumItalic,
  fontBoldItalic
} from 'styles';

export const Light = styled.span`
  ${fontLight}
`;
export const LightItalic = styled.span`
  ${fontLightItalic}
`;
export const Medium = styled.span`
  ${fontMedium}
`;
export const MediumItalic = styled.span`
  ${fontMediumItalic}
`;
export const Bold = styled.span`
  ${fontBold}
`;
export const BoldItalic = styled.span`
  ${fontBoldItalic}
`;
