import React from 'react';
import Tooltip from 'components/tooltip';
import { MediumItalic } from 'components/fonts';
import { allPrizes, allPrizesWithTypes } from 'utils/projectsHelpers';
import { Prize } from './styles';

const PrizeLink = ({ date, name, url }) => (
  <a key={name} href={url} target="_blank" rel="noreferrer noopener">
    {date}: {name}
  </a>
);

const renderPrizes = (prize) => (
  <Prize key={prize.type}>
    {prize.type} x{prize.count}
    {allPrizes.filter(({ type }) => type === prize.type).map(PrizeLink)}
  </Prize>
);

export default function Prizes() {
  return allPrizesWithTypes.map(({ provider, count, types }) => (
    <Tooltip
      key={provider}
      name={`${provider} prizes`}
      clickable={true}
      link={<MediumItalic>{`${provider} x${count}`}</MediumItalic>}
      tooltip={types.map(renderPrizes)}
    />
  ));
}
