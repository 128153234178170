import projects, { PrizeProviders, PrizeType } from 'projects';

export const projectsWithPrizes = projects.filter(
  (project) => !!project.prizes
);
// console.log('projectsWithPrizes', JSON.stringify(projectsWithPrizes));

export const allPrizes = projectsWithPrizes
  .map(({ prizes, name }) =>
    prizes.slice().map((prize) => ({ ...prize, name }))
  )
  .flat();
// console.log('allPrizes', JSON.stringify(allPrizes));

export const providers = [
  ...new Set(allPrizes.map(({ provider }) => provider)),
];
// console.log('providers', JSON.stringify(providers));

export const providerTypes = [...new Set(allPrizes.map(({ type }) => type))];
// console.log('providerTypes', JSON.stringify(providerTypes));

export const providersPrizesCount = {};
providers.forEach((provider) => {
  const count = allPrizes.filter((prize) => prize.provider === provider).length;
  providersPrizesCount[provider] = count;
});
// console.log('providersPrizesCount', JSON.stringify(providersPrizesCount));

export const providersTypesPrizesCount = {};
providerTypes.forEach((type) => {
  const count = allPrizes.filter((prize) => prize.type === type).length;
  providersTypesPrizesCount[type] = count;
});
// console.log(
//   'providersTypesPrizesCount',
//   JSON.stringify(providersTypesPrizesCount)
// );

export const allPrizesWithTypes = [];
Object.keys(PrizeProviders).forEach((provider) => {
  let providerCreated = false;
  Object.keys(PrizeType[provider]).forEach((type) => {
    const count = providersTypesPrizesCount[PrizeType[provider][type]];
    if (count) {
      if (!providerCreated) {
        providerCreated = true;
        allPrizesWithTypes.push({
          provider: PrizeProviders[provider],
          count,
          types: [],
        });
      } else {
        allPrizesWithTypes[allPrizesWithTypes.length - 1].count += count;
      }
      allPrizesWithTypes[allPrizesWithTypes.length - 1].types.push({
        type: PrizeType[provider][type],
        count,
      });
    }
  });
});
// console.log('allPrizesWithTypes', JSON.stringify(allPrizesWithTypes));

export const getProjectUrl = (project) =>
  encodeURI(`${project.year}-${project.name}`);

const findProjectIndex = (url) =>
  projects.findIndex((project) => url.includes(getProjectUrl(project)));

export const findProjectFromUrl = (url) => {
  const index = findProjectIndex(url);
  return index > -1 ? projects[index] : undefined;
};

export const getNextProjectUrl = (url) => {
  let index = findProjectIndex(url) + 1;
  if (index >= projects.length) index = 0;
  return getProjectUrl(projects[index]);
};

export const getPrevProjectUrl = (url) => {
  let index = findProjectIndex(url) - 1;
  if (index < 0) index = projects.length - 1;
  return getProjectUrl(projects[index]);
};
