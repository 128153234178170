import styled from 'styled-components';
import { pxToVw } from 'styles';
import theme from 'styles/theme';
import { isDesktop } from 'utils/device';

const base = isDesktop ? 4 : 8;
const borderSize = pxToVw(base);
const imgMargin = pxToVw(base / 2);

export const Wrapper = styled.div`
  position: relative;
  width: 25vw;
  height: 25vw;
  max-width: 250px;
  max-height: 250px;
  background-size: cover;
  background-image: url(${(props) => props.image});
  border-radius: 50%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: -${imgMargin} -${imgMargin};
    border-radius: 50%;
    border: solid ${theme.color.white} ${borderSize};
  }
`;
