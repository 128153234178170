import { css } from 'styled-components';

export const fullscreen = css`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const visible = css`
  opacity: 1;
`;
export const invisible = css`
  opacity: 0;
  pointer-events: none;
`;
