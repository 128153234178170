import React from 'react';
import ReactDOM from 'react-dom';
import { createHistory, LocationProvider } from '@reach/router';
import { ThemeProvider } from 'styled-components';

import App from 'containers/App';
import Loading from 'components/loading';
import GlobalStyle from 'styles/global';
import { renderIfElse } from 'utils/render';

import * as serviceWorker from './serviceWorker';
import theme from 'styles/theme';
import { trackPageView, trackTiming } from 'utils/analytics';

const startTime = performance.now();

const MOUNT_NODE = document.getElementById('root');
const history = createHistory(window);

history.listen(trackPageView);
trackPageView();

const renderApp = (isReady = true) => {
  ReactDOM.unmountComponentAtNode(MOUNT_NODE);
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <LocationProvider history={history}>
          {renderIfElse(isReady, <App />, <Loading />)}
        </LocationProvider>
      </ThemeProvider>
    </React.StrictMode>,
    MOUNT_NODE
  );
  if (isReady) {
    trackTiming({
      category: 'JS Libraries',
      variable: 'load',
      value: performance.now() - startTime,
      label: 'First full render',
    });
  }
};
renderApp(false);
var FontFaceObserver = require('fontfaceobserver');
var font = new FontFaceObserver('Roboto');
font
  .load()
  .catch(() => console.error('Font failed to load!'))
  .finally(renderApp);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
