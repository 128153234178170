import ReactGA from 'react-ga';
import { debounce } from 'debounce';

ReactGA.initialize('UA-31223126-1');

export const trackPageView = () =>
  ReactGA.pageview(window.location.pathname + window.location.search);

export const trackTiming = ({ category, variable, value, label }) =>
  ReactGA.timing({ category, variable, value, label });

export const trackModalView = debounce(
  (name) => ReactGA.modalview(name),
  200,
  true
);
