import React from 'react';
import image from 'assets/images/photo.jpg';
import { Wrapper } from './styles';

export default function Photo({ className }) {
  return (
    <Wrapper image={image.placeholder} className={className}>
      <img src={image.src} srcSet={image.srcSet} alt="" />
    </Wrapper>
  );
}
