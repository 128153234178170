import { css } from 'styled-components';

export const LinkNote = (content) => css`
  &:after {
    content: "${content ? content : '<-- see more'}";
    font-size: 0.6em;
    line-height: 2em;
    position: absolute;
    top: 0;
    left: calc(100% + 10px);
    opacity: 0.4;
    white-space: nowrap;
    transition: opacity 0.5s 5s;
  }

  &:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
    &:after {
      opacity: 0;
      transition-delay: 0s;
    }
  }
`;
