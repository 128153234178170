import styled from 'styled-components';
import { pxToVw } from 'styles';
import { isDesktop } from 'utils/device';

export const Wrapper = styled.div`
  text-align: center;
  font-size: ${pxToVw(isDesktop ? 16 : 40)};
`;

export const Company = styled.div`
  text-align: left;
  line-height: 1.6em;
`;

export const Prize = styled.div`
  & a {
    display: block;
    text-align: left;
    line-height: 1.6em;

    &:hover {
      text-decoration: underline;
    }
    &:first-of-type {
      margin-top: 1em;
    }
  }
`;
