import { css } from 'styled-components';

export const breakpoints = {
  mobile: 320,
  tablet: 768,
  desktop: 1024
};

export const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

// note layouts are different from device type, we may have mobile layout on desktop device
export const isPhoneLayout = () => window.innerWidth < breakpoints.tablet;
export const isTabletLayout = () =>
  window.innerWidth >= breakpoints.tablet &&
  window.innerWidth < breakpoints.desktop;
export const isDesktopLayout = () => window.innerWidth >= breakpoints.desktop;

// touch devices may have desktop layout depending on screen size
export const isTouchDevice = () =>
  !!('ontouchstart' in window || navigator.maxTouchPoints);

export const isLandscape = () => {
  const orientation = (window.screen.orientation || {}).type;

  return (
    window.orientation !== 0 ||
    orientation === 'landscape-primary' ||
    orientation === 'landscape-secondary' ||
    window.screen.width > window.screen.height
  );
};

export const isAndroid = () =>
  navigator.userAgent.toLowerCase().indexOf('android') > -1;

export const isIOS = () =>
  navigator.userAgent.indexOf('iPod') > -1 ||
  navigator.userAgent.indexOf('iPhone') > -1 ||
  navigator.userAgent.indexOf('iPad') > -1;
