import React, { useEffect } from 'react';
import { Redirect, Link } from '@reach/router';
import { OutboundLink } from 'react-ga';
import { Medium } from 'components/fonts';
import Vimeo from 'components/vimeo';
import {
  findProjectFromUrl,
  getPrevProjectUrl,
  getNextProjectUrl,
} from 'utils/projectsHelpers';
import { scrollTop } from 'utils/render';
import { Wrapper, Image } from './styles';

export default function Project(props) {
  const { uri } = props;

  useEffect(scrollTop, [uri]);

  const projectToRender = findProjectFromUrl(uri);

  if (projectToRender === undefined) {
    return <Redirect to="/projects" />;
  }

  const prevProjectUrl = `/projects/${getPrevProjectUrl(uri)}`;
  const nextProjectUrl = `/projects/${getNextProjectUrl(uri)}`;

  const {
    name,
    year,
    client,
    role,
    description,
    images,
    prizes,
    vimeo,
  } = projectToRender;

  return (
    <Wrapper>
      <nav>
        <Link to={prevProjectUrl}>&#8672; Previous</Link>
        <Link to="/projects">See other projects</Link>
        <Link to={nextProjectUrl}>Next &#8674;</Link>
      </nav>
      <h2>{name}</h2>
      <p>
        Year: <Medium>{year}</Medium>
      </p>
      <p>
        For: <Medium>{client}</Medium>
      </p>
      <p>
        Role: <Medium>{role}</Medium>
      </p>
      {description && (
        <p>
          About: <Medium>{description}</Medium>
        </p>
      )}
      {prizes && (
        <p>
          Prizes:
          {prizes.map(({ date, type, url }, key) => (
            <OutboundLink
              key={key}
              to={url}
              target="_blank"
              eventLabel={`${name} ${type}`}
            >
              <Medium>{type}</Medium> ({date})
            </OutboundLink>
          ))}
        </p>
      )}
      {vimeo && <Vimeo id={vimeo} />}
      <br />
      {images.map((image, key) => (
        <div key={key}>
          <Image {...image} />
          <br />
        </div>
      ))}
    </Wrapper>
  );
}
