import React from 'react';

import topLeft from 'assets/images/fwa/FOTD_top_left.png';
import topRight from 'assets/images/fwa/FOTD_top_right.png';
import bottomLeft from 'assets/images/fwa/FOTD_bottom_left.png';
import bottomRight from 'assets/images/fwa/FOTD_bottom_right.png';

import { TopLeft, TopRight, BottomLeft, BottomRight } from './styles';

export const FWA_TYPES = {
  FOTD: {
    TOP_LEFT: 'FOTDTopLeft',
    TOP_RIGHT: 'FOTDTopRight',
    BOTTOM_LEFT: 'FOTDBottomLeft',
    BOTTOM_RIGHT: 'FOTDBottomRight',
  },
};

const COMPONENTS = {
  [FWA_TYPES.FOTD.TOP_LEFT]: <TopLeft {...topLeft} />,
  [FWA_TYPES.FOTD.TOP_RIGHT]: <TopRight {...topRight} />,
  [FWA_TYPES.FOTD.BOTTOM_LEFT]: <BottomLeft {...bottomLeft} />,
  [FWA_TYPES.FOTD.BOTTOM_RIGHT]: <BottomRight {...bottomRight} />,
};

export default function FWA({ url, type = FWA_TYPES.FOTD.TOP_RIGHT }) {
  return (
    <a href={url} target="_blank" rel="noreferrer noopener">
      {COMPONENTS[type]}
    </a>
  );
}
