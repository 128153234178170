import { Link } from '@reach/router';
import styled from 'styled-components';
import { isDesktop } from 'utils/device';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  & h2 {
    margin-bottom: 4vmin;
  }

  & button {
    color: inherit;
  }
`;

export const ProjectsWrapper = styled.div`
  display: flex;
  align-items: ${isDesktop ? 'flex-start' : 'center'};
  flex-direction: ${isDesktop ? 'row' : 'column'};
  flex-wrap: wrap;
  justify-content: space-around;
`;

export const SeeMore = styled(Link)`
  margin: 10vmin 4vmin 20vmin;
  text-align: center;
`;
