import React, { useState, useEffect } from 'react';
import { Alt, Image } from './styles';

export default function (props) {
  // to get rid of `toString` method from props
  const { toString, ...imgProps } = props;
  const { placeholder, src, alt } = imgProps;
  const [loadingPlaceholder, setPlaceholder] = useState(placeholder);
  const [imgSrc, setImage] = useState(src);

  useEffect(() => {
    setPlaceholder(placeholder);
    setImage(undefined);
    setTimeout(() => setImage(src), 1);
  }, [src]); // eslint-disable-line react-hooks/exhaustive-deps

  if (imgSrc === undefined) {
    imgProps['src'] = undefined;
    imgProps['srcSet'] = undefined;
    imgProps['images'] = [];
  }

  return placeholder === undefined ? (
    <Alt {...imgProps}>{alt}</Alt>
  ) : (
    <Image
      key={alt}
      {...imgProps}
      placeholder={loadingPlaceholder}
      onLoad={() => setPlaceholder(undefined)}
    />
  );
}
