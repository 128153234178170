import noop from 'utils/noop';
import { RUN_ANIMATIONS } from 'config';
import { isDesktop } from 'utils/device';

const animationProps = () => ({
  xys: [0, 0, 1],
  mainOpacity: 0,
  gradientOpacity: isDesktop ? 0 : 0.4,
  filter: 0,
  nameOpacity: isDesktop ? 0 : 1,
  config: { mass: 1, tension: 150, friction: 20 },
});

const calc = (bounds, clientX, clientY) => {
  const { left, top, width, height } = bounds;
  const x = clientX - left - width / 2;
  const y = clientY - top - height / 2;
  const angle = 15;
  return [(-y / height) * angle, (x / width) * angle, isDesktop ? 1.05 : 1.1];
};
const wrapperTans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const nameTrans = (x, y) => `translate3d(${x}px,${y}px,0)`;
const previewTrans = (x, y) => `translate3d(${-x / 3}px,${-y / 3}px,0)`;

const springs = ([props, set, stop]) => {
  if (!RUN_ANIMATIONS || !props) return { setVisible: noop, wrapper: noop };

  return {
    setVisible: (visible) => set({ mainOpacity: visible ? 1 : 0 }),
    wrapper: (wrapper) => ({
      onMouseMove: ({ clientX, clientY }) =>
        set({
          xys: calc(wrapper.current.getBoundingClientRect(), clientX, clientY),
          gradientOpacity: 0.7,
          nameOpacity: 1,
          filter: 1,
        }),
      onMouseLeave: () => {
        set({
          xys: [0, 0, 1],
          gradientOpacity: 0,
          nameOpacity: isDesktop ? 0 : 1,
          filter: 0,
        });
        stop();
      },
      style: {
        transform: props.xys.interpolate(wrapperTans),
        opacity: props.mainOpacity.interpolate((o) => o),
        willChange: props.xys.interpolate((x, y, s) =>
          s === 1 ? 'auto' : 'transform, opacity'
        ),
      },
    }),
    preview: {
      style: {
        transform: props.xys.interpolate(previewTrans),
        // filter: props.filter.interpolate((o) => `grayscale(${o * 100}%)`),
        willChange: props.filter.interpolate((o) =>
          o === 0 ? 'auto' : 'transform, filter'
        ),
      },
    },
    gradient: {
      style: {
        opacity: props.gradientOpacity.interpolate((o) => o),
        willChange: props.gradientOpacity.interpolate((o) =>
          o === 0 ? 'auto' : 'opacity'
        ),
      },
    },
    name: {
      style: {
        transform: props.xys.interpolate(nameTrans),
        opacity: props.nameOpacity.interpolate((o) => o),
        willChange: props.nameOpacity.interpolate((o) =>
          o === 0 ? 'auto' : 'transform, opacity'
        ),
      },
    },
  };
};

export default function (func) {
  return springs(func(animationProps));
}
