import React from 'react';

export default function Vimeo({ id }) {
  return (
    <>
      <div
        style={{
          padding: '56.25% 0 0 0',
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
        dangerouslySetInnerHTML={{
          __html: `<iframe
              src='https://player.vimeo.com/video/${id}?title=1&byline=1&portrait=1'
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen
            ></iframe>`,
        }}
      ></div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
}
