import styled from 'styled-components';
import { animated } from 'react-spring';

const backgroundImage = (placeholder) =>
  placeholder &&
  `
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url(${placeholder});
  `;

export const Image = styled(animated.img)`
  ${({ placeholder }) => backgroundImage(placeholder)}
`;

export const Alt = styled(animated.figcaption)``;
