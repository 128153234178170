import React, { useEffect } from 'react';
import projects from 'projects';

import { scrollTop } from 'utils/render';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ProjectPreview from 'components/projectPreview';
import { ProjectsWrapper, Wrapper, SeeMore } from './styles';
import { Bold } from 'components/fonts';
import { convertArrayToObject } from 'utils/arrayToObject';

const TECHNOLOGIES = [...new Set(projects.map(({ tech }) => tech))];
let tempKeywords = [];
projects.forEach((project) => {
  tempKeywords = tempKeywords.concat(project.keywords);
});
const KEYWORDS = [...new Set(tempKeywords)];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default function Projects(props) {
  const { limit, uri } = props;

  useEffect(scrollTop, [uri]);

  const classes = useStyles();

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [technology, setTechnology] = React.useState(
    convertArrayToObject(TECHNOLOGIES)
  );
  const [keywords, setKeywords] = React.useState(
    convertArrayToObject(KEYWORDS)
  );

  let techProjects = [];
  let filteredProjects = [];
  TECHNOLOGIES.filter((tech) => technology[tech]).forEach((tech) =>
    projects
      .filter((project) => project.tech === tech)
      .forEach((project) => techProjects.push(project))
  );
  KEYWORDS.filter((keyword) => keywords[keyword]).forEach((keyword) => {
    techProjects
      .filter(
        (project) =>
          project.keywords.includes(keyword) &&
          !filteredProjects.includes(project)
      )
      .forEach((project) => filteredProjects.push(project));
  });

  // filteredProjects = [...new Set(filteredProjects)];
  const projectsToRender = filteredProjects.slice(
    0,
    limit ? limit : filteredProjects.length
  );
  const howManyMore = filteredProjects.length - projectsToRender.length;

  const handleChangeTechnology = (e) =>
    setTechnology({ ...technology, [e.target.name]: e.target.checked });
  const handleChangeKeyword = (e) =>
    setKeywords({ ...keywords, [e.target.name]: e.target.checked });

  const handleClickFiltersOpen = () => setFiltersOpen(true);
  const handleFiltersClose = () => setFiltersOpen(false);

  const activeFilters = TECHNOLOGIES.filter((tech) => technology[tech]).concat(
    KEYWORDS.filter((keyword) => keywords[keyword])
  );

  const filtersString =
    activeFilters.length === TECHNOLOGIES.length + KEYWORDS.length
      ? 'ALL'
      : `${activeFilters.length} active`;
  return (
    <Wrapper>
      <h2>{limit ? 'Recent' : 'Featured'} Projects</h2>

      <div>
        <Button onClick={handleClickFiltersOpen}>
          Change filters: ({filtersString})
        </Button>
        <Dialog open={filtersOpen} onClose={handleFiltersClose}>
          <DialogTitle>Fill the form</DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl}>
              <FormLabel component="legend">Technology</FormLabel>
              <FormGroup row>
                {TECHNOLOGIES.map((tech) => (
                  <FormControlLabel
                    key={tech}
                    control={
                      <Checkbox
                        checked={technology[tech]}
                        onChange={handleChangeTechnology}
                        name={tech}
                      />
                    }
                    label={tech}
                  />
                ))}
              </FormGroup>

              <FormLabel component="legend">Keywords</FormLabel>
              <FormGroup row>
                {KEYWORDS.map((keyword) => (
                  <FormControlLabel
                    key={keyword}
                    control={
                      <Checkbox
                        checked={keywords[keyword]}
                        onChange={handleChangeKeyword}
                        name={keyword}
                      />
                    }
                    label={keyword}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFiltersClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      <ProjectsWrapper>
        {projectsToRender.map((project) => (
          <ProjectPreview {...project} key={project.name} />
        ))}
      </ProjectsWrapper>
      {howManyMore > 0 && (
        <SeeMore to="/projects">
          <Bold>Check all projects</Bold>
          <br />({howManyMore} more to see)
        </SeeMore>
      )}
    </Wrapper>
  );
}
