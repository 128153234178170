import React from 'react';
import Photo from './photo';
import Info from './description';
import { PhotoWrapper, Wrapper } from './styles';

export default function Bio() {
  return (
    <Wrapper>
      <PhotoWrapper>
        <Photo />
      </PhotoWrapper>
      <Info />
    </Wrapper>
  );
}
