import { __, curryN } from 'lodash/fp';
import { type } from 'ramda';

export const renderIfElse = curryN(3, (cond, onTrue, onFalse) => {
  const typeWrapper = (arg) => (type(arg) === 'Function' ? arg() : arg);
  return cond === true ? typeWrapper(onTrue) : typeWrapper(onFalse);
});

export const renderIf = renderIfElse(__, __, null);

export const scrollTop = () => window.scrollTo(0, 0);
