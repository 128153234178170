import styled from 'styled-components';
import ImageComponent from 'components/image';
import { isDesktop } from 'utils/device';
import { LinkNote, layout, color, fontBold } from 'styles';

export const Wrapper = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto 12vmin;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${!isDesktop && `font-size: 0.7rem;`}
  & h2 {
    margin-bottom: 1em;
    align-self: center;
  }

  & p {
    position: relative;
    margin-bottom: 0.5em;
    width: 100%;
    &:last-of-type {
      margin-bottom: 2em;
    }

    & > span,
    & > a {
      position: absolute;
      left: 60px;
    }
    & a {
      ${LinkNote(`<-- ${isDesktop ? 'click' : 'tap'} to see more`)}
    }
  }

  & nav {
    ${color.headerBlendMode}
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${layout.projectsNavSize};
    line-height: ${layout.projectsNavSize};
    padding: 0 5%;

    border-top: solid white 1px;
    background-color: ${color.header};

    display: flex;
    justify-content: space-between;
    align-content: center;

    & a {
      ${fontBold}
      color: #fff;
      display: inline-block;
      text-shadow: 1px 1px 1px #006bad;
    }
  }

  & footer {
    margin: 10vmin auto;
  }
`;

export const Image = styled(ImageComponent)`
  width: 100%;
  height: auto;
`;
