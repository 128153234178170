import React from 'react';
import { Link } from '@reach/router';
import { OutboundLink } from 'react-ga';
import { INFO, DOMAIN_NAME } from 'config';
import Linkedin from 'components/svg/linkedin';
import Mail from 'components/svg/mail';
import { Wrapper, Contact, Header } from './styles';

export default function () {
  const { mail, linkedin } = INFO;
  return (
    <Wrapper>
      <Link to="/">
        <Header>{DOMAIN_NAME}</Header>
      </Link>
      <Contact>
        <OutboundLink to={linkedin} target="_blank" eventLabel="LinkedIn">
          <Linkedin />
        </OutboundLink>
        <OutboundLink to={`mailto:${mail}`} target="_blank" eventLabel="Mail">
          <Mail />
        </OutboundLink>
      </Contact>
    </Wrapper>
  );
}
