/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link, ReactTooltip } from './styles';
import { trackModalView } from 'utils/analytics';

export default function Tooltip(props) {
  const { name, link, tooltip, type = 'dark', clickable } = props;

  const [isVisible, setVisible] = useState(false);

  const setActive = () => {
    setVisible(true);
    trackModalView(name);
  };

  const afterHide = () => setVisible(false);
  const afterShow = () => setTimeout(setActive, 1);

  const isClickable = isVisible && clickable;

  return (
    <>
      <Link data-tip data-for={name}>
        {link}
      </Link>
      <ReactTooltip
        id={name}
        type={type}
        place="bottom"
        effect="solid"
        arrowColor="rgba(0,0,0,0)"
        offset={{ top: 20 }}
        afterShow={afterShow}
        afterHide={afterHide}
        clickable={isClickable}
        pointerEvents={isClickable}
      >
        {tooltip}
      </ReactTooltip>
    </>
  );
}
