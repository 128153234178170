import styled from 'styled-components';
import Image from 'components/image';

const Wrapper = styled(Image)`
  z-index: 1;
  position: absolute;
  width: auto;
  max-height: 25%;
  min-height: 50px;

  transition: transform 0.25s;
  &:hover {
    transform: scale(1.2);
  }
`;

export const TopLeft = styled(Wrapper)`
  top: 0;
  left: 0;
`;

export const TopRight = styled(Wrapper)`
  top: 0;
  right: 0;
`;

export const BottomLeft = styled(Wrapper)`
  bottom: 0;
  left: 0;
`;

export const BottomRight = styled(Wrapper)`
  bottom: 0;
  right: 0;
`;
