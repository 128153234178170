import styled from 'styled-components';
import { pxToVw } from 'styles';

export const Wrapper = styled.div`
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: ${pxToVw(20)};
`;
